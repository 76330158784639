var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"censor-word"},[(!_vm.currentView)?_c('div',[_c('filter-levels',{attrs:{"filter-type":"censor_word","extra-menu-items":[
        _vm.ExtraSettingsMenuItems.CommonRules,
        _vm.ExtraSettingsMenuItems.VirtualNew ].concat( _vm.$store.getters.isRuChat ? [_vm.ExtraSettingsMenuItems.AdvancedSettings] : [] )},on:{"changeCurrentView":_vm.handleCurrentView}}),_c('div',{staticClass:"main-filter-settings mt-5"},[_c('a-card',{attrs:{"title":_vm.$t('field_special_settings_title')}},[_c('strict-mode',{attrs:{"filter-type":"censor_word","store-path":"$store.state.chatState.configCopy","pick-fields":[_vm.StrictModeFields.Strict_mode, _vm.StrictModeFields.Custom_dict]}}),_c('ignore-caption',{attrs:{"filter-type":"censor_word","store-path":"$store.state.chatState.configCopy"}})],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_common_settings_title')}},[_c('common-settings',{attrs:{"filter-type":"censor_word","store-path":"$store.state.chatState.configCopy"}})],1)],1)],1):_c('div',[(_vm.currentView === _vm.ExtraSettingsMenuItems.AdvancedSettings)?_c('div',[_c('section-wrapper',{attrs:{"section-title":"advanced_settings"},on:{"onNavBack":_vm.handleNavBack},scopedSlots:_vm._u([{key:"fields",fn:function(){return [_c('switch-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.$store.state.chatState.configCopy,
                'key': 'censor_word_fuck',
                'hasAnchor': true
              }
            }}}),_c('switch-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.$store.state.chatState.configCopy,
                'key': 'censor_word_govno',
                'hasAnchor': true
              }
            }}}),_c('switch-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.$store.state.chatState.configCopy,
                'key': 'censor_word_jopa',
                'hasAnchor': true
              }
            }}}),_c('switch-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.$store.state.chatState.configCopy,
                'key': 'censor_word_mudak',
                'hasAnchor': true
              }
            }}})]},proxy:true}],null,false,3035090638)})],1):_vm._e(),(_vm.currentView === _vm.ExtraSettingsMenuItems.VirtualNew)?_c('div',[_c('section-wrapper',{attrs:{"section-title":"virtual_new"},on:{"onNavBack":_vm.handleNavBack},scopedSlots:_vm._u([{key:"fields",fn:function(){return [_c('virtual-new-settings',{attrs:{"filter-type":"censor_word","store-path":"$store.state.chatState.configCopy"}})]},proxy:true}],null,false,781226594)})],1):_vm._e(),(_vm.currentView === _vm.ExtraSettingsMenuItems.CommonRules)?_c('div',[_c('section-wrapper',{attrs:{"section-title":"common_rules"},on:{"onNavBack":_vm.handleNavBack},scopedSlots:_vm._u([{key:"fields",fn:function(){return [_c('common-rules-settings',{attrs:{"filter-type":"censor_word","store-path":"$store.state.chatState.configCopy"}})]},proxy:true}],null,false,1697992399)})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }